import React from "react"
import { Link } from "gatsby"
//react component for footer
class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
            <div className="footerSocial">
                <a href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a>
                <a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a>
                <a href="https://linkedin.com/himlatak"></a>
            </div>
            <div className="footerCol">
                <div className="footerObj">
                    <p className="footerHead">Produkt</p>
                    <ul className="footerList">
                        <li><Link to="/om#takfonster">Virtuella takfönster</Link></li>
                        <li><Link to="/om#fonster">Virtuella fönster</Link></li>
                        <li><Link to="#">LED-paneler</Link></li>
                        <li><Link to="/bildgalleri">Bildgalleri</Link></li>
                    </ul>
                </div>
                <div className="footerObj">
                    <p className="footerHead">Om</p>
                    <ul className="footerList">
                        <li><Link to="/om">Om produkterna</Link></li>
                        <li><Link to="/om">Om Himlatak</Link></li>
                        {/* <li><Link to="#">Företagsleasing</Link></li> */}
                        {/* <li><Link to="#">FAQ</Link></li> */}
                    </ul>
                </div>
                <div className="footerObj">
                    <p className="footerHead">kontakt</p>
                    <ul className="footerList">
                        <li><a href="#">kontakt@himlatak.se</a></li>
                        <li><a href="#">020-89 92 89</a></li>
                        <li><a href="#">Banérgatan 8, 114 56 Stockholm</a></li>
                    </ul>
                </div>
            </div>
            <div className="lineBreak"></div>
            <div className="footerCopyright">
                <div className="copyCol">
                    <p> © {new Date().getFullYear()}, Himlatak</p>
                </div>
                <div className="copyCol">
                    <a href="/privacy-policy">Privacy Policy</a>
                    <a href="/cookies">Cookies</a>
                </div>
            </div>
        </div>
      </footer>
    )
  }
}

export default Footer;