import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle }) => (
  <header>
    <div className="headContainer">
      <div className="headLogo">
        <Link to="/">
          <StaticImage src="../images/himlatakLogo.png" alt="logo" placeholder="blurred"
            layout="fixed"
            height={50} />
        </Link>
      </div>
      <div className="headNav">
        <div className="headNavItem">
          <Link to="/">Hem</Link>
        </div>
        <div className="headNavItem">
          <Link to="/om">Om</Link>
        </div>
        <div className="headNavItem">
          <Link to="/kontakt">Kontakt</Link>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
